import React from "react";

const ChatVariant = () => (
  <svg
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.75 9C6.75 8.58579 7.08579 8.25 7.5 8.25L15.5 8.25C15.9142 8.25 16.25 8.58579 16.25 9C16.25 9.41421 15.9142 9.75 15.5 9.75L7.5 9.75C7.08579 9.75 6.75 9.41421 6.75 9Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.75 13C6.75 12.5858 7.08579 12.25 7.5 12.25H11.5C11.9142 12.25 12.25 12.5858 12.25 13C12.25 13.4142 11.9142 13.75 11.5 13.75H7.5C7.08579 13.75 6.75 13.4142 6.75 13Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.5 1.75C6.39137 1.75 2.25 5.89137 2.25 11C2.25 12.6861 2.70043 14.2647 3.48703 15.6245C3.57666 15.7794 3.60792 15.9613 3.57515 16.1373L2.93742 19.5626L6.36272 18.9248C6.5387 18.8921 6.7206 18.9233 6.87554 19.013C8.23533 19.7996 9.81393 20.25 11.5 20.25C16.6086 20.25 20.75 16.1086 20.75 11C20.75 5.89137 16.6086 1.75 11.5 1.75ZM0.75 11C0.75 5.06294 5.56294 0.25 11.5 0.25C17.4371 0.25 22.25 5.06294 22.25 11C22.25 16.9371 17.4371 21.75 11.5 21.75C9.64369 21.75 7.89536 21.2789 6.37016 20.4492L2.13728 21.2373C1.89423 21.2826 1.64449 21.2051 1.46967 21.0303C1.29485 20.8555 1.21742 20.6058 1.26267 20.3627L2.05076 16.1298C1.22113 14.6046 0.75 12.8563 0.75 11Z"
      fill="currentColor"
    />
  </svg>
);

export default ChatVariant;
